// import hoge from "./modules/module1"

const scrollElm = (function () {
  if (document.scrollingElement) return document.scrollingElement;
  if (navigator.userAgent.indexOf('WebKit') != -1) return 'body';
  return document.documentElement;
})();

let headerHeight = 54;
let footerHeight = 124;
let hfShutterHeight = "";
let bs = 0;
let as = 0;
let startY = 0;
let endY = 0;
let scrollDuration = 4000;
let scrollIdx = 0;

let _url = location.href;
if(!_url.match("/contact/") || $("*").children().hasClass("js-scroll")) {
  ScrollTrigger.clearScrollMemory("manual");
}

const body = document.querySelector('body');
const header = document.querySelector('.site-header');
const headerBg = header.querySelector('.site-header-bg');
const footer = document.querySelector('.site-footer');
const footerBg = footer.querySelector('.site-footer-bg');
const footerContents = footer.querySelector('.site-footer-content');
const scrollDown = footer.querySelector('.scrolldown');
const scrollDownText = footer.querySelector('.scrolldown > span');
const scrollUp = header.querySelector('.scrollup');
const scrollUpText = header.querySelector('.scrollup > span');

$(function(){
  const setHeaderHeight = () => {
    if($(window).innerWidth() < 769) {
      headerHeight = 32;
    } else {
      headerHeight = 54;
    }
    return headerHeight;
  }
  const setFooterHeight = () => {
    if($(window).innerWidth() <= 1280) {
      footerHeight = 101;
    } else {
      footerHeight = 124;
    }
    return footerHeight;
  }
  const setHfShutterHeight = () => {
    if($(window).innerWidth() <= 1280) {
      hfShutterHeight = (window.innerHeight / 2) - 1;
    } else {
      hfShutterHeight = "calc(50vh - 1px)";
    }
    return hfShutterHeight;
  }

  const introAnim = (tl, el, i) => {
    let linePos = i % 2 == 1 ? 1192 : 206;
    const sectionContents = el.querySelector('.section-inner');
    const sectionBg = el.querySelector('.section-bg');

    tl.to(header, { height: ()=> setHeaderHeight(), duration: 1})
      .to(headerBg, { "--cell-width": linePos, duration: 1 }, '<')
      .to(footer, { height: ()=> setFooterHeight(), duration: 1 }, '<')
      .to(footerBg, { "--cell-width": linePos, duration: 1 }, '<')
      .addLabel("header-footer-open")
      .add(() =>  body.dataset.currentSection = i )
      .from(sectionBg, { "--section-bg-brightness": 1, duration: 1})
      .from(sectionBg, { "--section-bg-shadow": "50%", duration: 1}, '<')
      .from(sectionBg, { "--section-bg-blur": "30px", duration: 1}, '<')
      .from(sectionContents, { "--section-contents-opacity": "0", duration: 0.1}, '<')
      .from(sectionContents, { "--section-contents-blur": "30px", duration: 1}, '<')
      .from(sectionContents, { "--section-contents-translate": "30px", duration: 1}, '<')
      .from(footerContents, { "--footer-contents-blur": "30px", duration: 1}, '<')
      .from(footerContents, { "--footer-contents-translate": "30px", duration: 1}, '<')
      .from(footerContents, { opacity: 1}, '<')
      .to(sectionBg, { "--section-bg-brightness": 0, duration: 1})
      .to(sectionBg, { "--section-bg-shadow": "100%", duration: 1}, '<')
      .to(sectionBg, { "--section-bg-blur": 0, duration: 1}, '<')
      .to(sectionContents, { "--section-contents-blur": 0, duration: 1}, '<')
      .to(sectionContents, { "--section-contents-translate": 0, duration: 1}, '<')
      .to(footerContents, { "--footer-contents-blur": 0, duration: 1}, '<')
      .to(footerContents, { "--footer-contents-translate": 0, duration: 1}, '<')
      .addLabel("contents-in")
  }

  const outroAnim = (tl, el, i) => {
    let linePos = i % 2 == 1 ? 1192 : 206;
    const sectionContents = el.querySelector('.section-inner');
    const sectionBg = el.querySelector('.section-bg');

    tl.to(sectionBg, { "--section-bg-brightness": 1, duration: 1 })
      .to(sectionContents, { "--section-contents-opacity": "0", duration: 1}, '<')
      .to(sectionBg, { "--section-bg-shadow": "50%", duration: 1 }, '<')
      .to(sectionBg, { "--section-bg-blur": "30px", duration: 1}, '<')
      .to(sectionContents, { "--section-contents-blur": "30px", duration: 1}, '<')
      .to(sectionContents, { "--section-contents-translate": "-30px", duration: 1}, '<')
      .to(footerContents, { "--footer-contents-blur": "30px", duration: 1}, '<')
      .to(scrollDownText, { "--footer-contents-blur": "30px", duration: 1}, '<')
      // .to(scrollDown, { "--footer-contents-blur": "30px", duration: 1}, '<')
      .to(footerContents, { "--footer-contents-translate": "-30px", duration: 1}, '<')
      // .to(scrollDownText, { "--footer-contents-translate": "-30px", duration: 1}, '<')
      // .to(scrollDown, { "--footer-contents-translate": "-30px", duration: 1}, '<')
      .to(footerContents, { "--footer-contents-translate": "-30px", duration: 1}, '<')
      // .to(scrollDownText, { "--footer-contents-translate": "-30px", duration: 1}, '<')
      // .to(scrollDown, { "--footer-contents-translate": "-30px", duration: 1}, '<')
      // .to(scrollDownText, { opacity: 0, duration: 1}, '<')
      .to(footerContents, { opacity: 0, duration: 1})
      .addLabel("contents-out")
      .add(() =>  body.dataset.currentSection = i )
      .to(header, { height: ()=> setHfShutterHeight(), duration: 1})
      .to(headerBg, { "--cell-width": 720, duration: 1 }, '<')
      .to(footer, { height: ()=> setHfShutterHeight(), duration: 1 }, '<')
      .to(footerBg, { "--cell-width": 720, duration: 1 }, '<')
      .addLabel("header-footer-close")
  }

  //init
  if(!_url.match("/contact/") || $("*").children().hasClass("js-scroll")) {
    gsap.utils.toArray('.js-scroll').forEach((el, i, arr) => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          pin: true,
          start: "top top",
          end: "bottom top",
          scrub: 1,
          invalidateOnRefresh: false,
          toggleClass: {
            targets: body,
            className: "open-contents",
          },
        },
      });

      if (arr.length - 1 == i) {
        introAnim(tl, el, i)
      } else {
        introAnim(tl, el, i)
        outroAnim(tl, el, i)
      }
    });
  }

  // 言語切り替えボタン
  $(".language-btn").on("click", function(){
    $(".language-list-wrapper").slideToggle();
  });

  //スクロール要素リスト
  const scrollPoint = [];
  $(".pin-spacer").each(function(){
    scrollPoint.push($(this).position().top);
  });

  //スクロール制御
  document.addEventListener("wheel", scrollControl, { passive: false });
  document.addEventListener("touchend", scrollControl);
  document.addEventListener("touchstart", function(e) {
    startY = e.touches[0].pageY;
  });
  document.addEventListener("touchmove", function(e) {
    //コンテンツ内でスクロールさせたい要素だったらreturn
    const scrollTarget = $(e.target);
    if(scrollTarget.hasClass("js-scroll-exclude") || scrollTarget.parents(".js-scroll-exclude").length) return;

    e.preventDefault();
    endY = e.changedTouches[0].pageY;
  }, { passive: false });

  //resizeによる値変更
  $(window).on("resize", function(e){
    setHeaderHeight();
    setFooterHeight();
    setHfShutterHeight();
    $(".section-bg").height(window.innerHeight - headerHeight - footerHeight);

    scrollDuration = ($(window).innerWidth() < 769)? 3000 : 3000;
    if(!_url.match("/contact/") || $("*").children().hasClass("js-scroll")) {
      ScrollTrigger.refresh();
    }

    contentsScroll(scrollIdx, 0);
  });
  $(window).resize();

  // 矢印キー押下制御
  document.addEventListener("keydown", function(e){
    if(e.key === "ArrowDown" || e.key === "ArrowUp"){
      e.preventDefault();
    }
  });
  document.addEventListener("keyup", function(e){
    if(e.key === "ArrowDown"){
      e.preventDefault();

      scrollIdx++;
      contentsScroll(scrollIdx, scrollDuration);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();

      scrollIdx--;
      contentsScroll(scrollIdx, scrollDuration);
    }
    return false;
  });

  // content-boxスクロール制御
  let contents = document.getElementsByClassName("content-box");
  let content = Array.from(contents);
  content.forEach(function(target) {
    // マウスホイール
    target.addEventListener("wheel", function(e){
      // スクロール方向取得
      let scrollY = 0;
      if (e.type == "wheel") {
        scrollY = e.deltaY;
      }
      if(this.scrollTop === 0 && scrollY < 0) {
        //イベント1回だけ処理
        let ts = e.timeStamp;
        if(bs == 0) {
          bs = ts;
        } else {
          as = ts;
          if(as - bs < scrollDuration + 100) {
            return false;
          } else {
            bs = ts;
          }
        }
        e.preventDefault();
        scrollIdx = scrollPoint.length - 1;
        contentsScroll(scrollIdx, scrollDuration);
        $(".scrollup").addClass("is-visible");
        $(".scrolldown").removeClass("is-visible");
      }
    }, { passive: false });
    target.addEventListener("touchmove", function(e){
      // スクロール方向取得
      let scrollY = 0;
      if (e.type == "touchmove") {
        endY = e.changedTouches[0].pageY;
        scrollY = (endY - startY) * -1;
      }
      if(this.scrollTop === 0 && scrollY < 0) {
        //イベント1回だけ処理
        let ts = e.timeStamp;
        if(bs == 0) {
          bs = ts;
        } else {
          as = ts;
          if(as - bs < scrollDuration + 100) {
            return false;
          } else {
            bs = ts;
          }
        }
        e.preventDefault();
        scrollIdx = scrollPoint.length - 1;
        contentsScroll(scrollIdx, scrollDuration);
        $(".scrollup").addClass("is-visible");
        $(".scrolldown").removeClass("is-visible");
      }
    }, { passive: false });
  });

  // アクセス時にページを表示
  scrollIdx++;
  contentsScroll(scrollIdx, 2000);

  function scrollControl(e) {
    //コンテンツ内でスクロールさせたい要素だったらreturn
    const scrollTarget = $(e.target);
    if(scrollTarget.hasClass("js-scroll-exclude") || scrollTarget.parents(".js-scroll-exclude").length) return;

    let scrollY = 0;
    if (e.type == "wheel") {
      e.preventDefault();
      scrollY = e.deltaY;
    } else {
      endY = e.changedTouches[0].pageY;
      scrollY = (endY - startY) * -1; //上下判定がwheelと逆のため-1掛け
    }

    //イベント1回だけ処理
    let ts = e.timeStamp;
    if(bs == 0) {
      bs = ts;
    } else {
      as = ts;
      if(as - bs < scrollDuration + 100) {
        return false;
      } else {
        bs = ts;
      }
    }
    scrollDirection(e);

    if (scrollY > 0) {
      if (scrollIdx == scrollPoint.length) return;
      scrollIdx++;
    } else {
      if (scrollIdx == 1) return;
      scrollIdx--;
    }

    contentsScroll(scrollIdx, scrollDuration);
  }

  function contentsScroll(idx, duration) {
    // $(".pin-spacer").css({
    //   "height": window.innerHeight * 2,
    //   "padding-bottom": window.innerHeight
    // });

    //const windowHeight = window.innerHeight;
    const windowHeight = $(".pin-spacer").eq(2).height();
    let st = $(window).scrollTop();
    let scrollTop = scrollPoint[idx];
    if(idx == 0) {
      scrollTop = scrollPoint[idx];
    } else if(idx == 1) {
      scrollTop = windowHeight / 2;
    } else if(idx == 2) {
      scrollTop = (idx) * windowHeight + (windowHeight / 2);
    } else if(idx == scrollPoint.length) {
      scrollTop = (idx - 1) * (windowHeight * 2) + (windowHeight);
    } else {
      scrollTop = (idx - 1) * (windowHeight * 2) + (windowHeight / 2);
      //scrollTop = scrollPoint[idx] - (windowHeight * 2) + (windowHeight / 2);
      //scrollTop = scrollPoint[idx] - (windowHeight * 2) + (windowHeight / 2);
    }

    let sta = scrollTop - st;
    sta = sta / 4;

    $(scrollElm).animate({scrollTop: st + sta}, duration / 3, "linear");
    $(scrollElm).animate({scrollTop: st + (sta * 3)}, duration / 4, "linear");
    $(scrollElm).animate({scrollTop: scrollTop}, duration / 3, "linear");

    return false;
  }


  const scrollDirection = (e) => {
    let scrollY = 0;
    if (e.type == "wheel") {
      e.preventDefault();
      scrollY = e.deltaY;
    } else {
      endY = e.changedTouches[0].pageY;
      scrollY = (endY - startY) * -1; //上下判定がwheelと逆のため-1掛け
    }

    if(scrollY < 0) {
      $(".scrollup").addClass("is-visible");
      $(".scrolldown").removeClass("is-visible");
    } else {
      $(".scrolldown").addClass("is-visible");
      $(".scrollup").removeClass("is-visible");
    }
  }
});